import React from "react"
import { Card } from "../components/card/Card"
import { Page } from "../components/layout"
import { UniversalLink } from "../components/UniversalLink"
import "./about.scss"

export const About = () => (
  <Page
    header="About"
    title="About Us"
    keywords="xrpl developer resources, xrpl grants questions, xrpl grants application, xrpl grants timeline, xrpl grants about"
    description="The XRPL Grants program supports the development of new software projects built on the XRP Ledger (XRPL). Join the growing XRPL developer community!"
    slug="about"
  >
    <div className="about-info grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
      <p className="longForm col-span-2 sm:col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3">
        The XRPL Grants program was founded in May 2021 to support and encourage
        the development of new software projects on the XRP Ledger (XRPL) and
        help foster the growing XRPL developer community.
        <br />
        <UniversalLink
          to="/grantees"
          className="btn btn-primary btn-auto past-grantees-button md:mh-auto"
        >
          View Past Grantees
        </UniversalLink>
      </p>
    </div>
    <div className="grants-stats">
      <div className="orange-rectangle" />
      <Card className="grants-stats-card" trim={false}>
        <div className="stats-data funding-data">
          <h3>$15M+</h3>
          <p>in funding has been awarded</p>
        </div>
        <div className="stats-data teams-awarded-data">
          <h3>140+</h3>
          <p>teams awarded globally</p>
        </div>
        <div className="stats-data countries-data">
          <h3>30+</h3>
          <p>countries represented</p>
        </div>
      </Card>
    </div>
    <div className="xrpl-info">
      <div className="title-description grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <h2 className="col-span-2 sm:col-span-4 lg:col-span-6">
          The XRP Ledger
        </h2>
        <div className="description col-span-2 sm:col-span-4 lg:col-span-6">
          <p>
            The XRP Ledger is open source and permissionless, delivering
            powerful utility to developers on a public blockchain. Build with
            confidence and transform your project into a sustainable thriving
            business. With legal clarity, decade-long proven performance, and a
            dedicated community, the XRPL offers you the chance to build on rock
            solid foundations.
          </p>
          <UniversalLink
            to="https://xrpl.org"
            className="visit-xrplorg-button btn btn-primary btn-auto"
          >
            Visit XRPL.org
          </UniversalLink>
        </div>
      </div>
    </div>
    <div className="dev-resources">
      <div className="title-description grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <h2 className="col-span-2 sm:col-span-4 lg:col-span-6">
          Developer Resources
        </h2>
        <div className="description col-span-2 sm:col-span-4 lg:col-span-6">
          <p>
            You don’t need to receive a grant to join the XRPL developer
            community! XRPL.org contains resources to get you started and to
            connect with the growing community, including:
          </p>
        </div>
      </div>
      <div className="dev-res-cards grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <UniversalLink
          to="https://learn.xrpl.org"
          className="tech-doc-card col-span-2 md:col-span-4"
        >
          <Card title="Learning Portal" trim="magenta">
            <p className="description">
              Take self-paced courses on crypto, blockchain, and coding on the
              XRPL.
            </p>
          </Card>
        </UniversalLink>
        <UniversalLink
          to="https://xrpl.org/docs.html"
          className="tech-doc-card col-span-2 md:col-span-4"
        >
          <Card title="Technical Documentation" trim="blue">
            <p className="description">
              Browse concept documentation, tutorials, references, and code
              samples.
            </p>
          </Card>
        </UniversalLink>
        <UniversalLink
          to="https://xrpl.org/dev-tools.html"
          className="dev-tools-card col-span-2 md:col-span-4"
        >
          <Card title="Developer Tools" trim="orange">
            <p className="description">
              Utilize tools to help test, explore, and validate XRPL API
              requests and behavior.
            </p>
          </Card>
        </UniversalLink>
        <UniversalLink
          to="https://xrpl.org/events.html"
          className="events-meetups-card col-span-2 md:col-span-4"
        >
          <Card title="Community & Events" trim="purple">
            <p className="description">
              Find XRPL events and meet-ups, learn about new projects, and view
              additional opportunities.
            </p>
          </Card>
        </UniversalLink>
        <UniversalLink
          to="https://xrpldevs.org"
          className="dev-tools-card col-span-2 md:col-span-4"
        >
          <Card title="Discord" trim="green">
            <p className="description">
              Connect and share with the XRPL Developer Community.
            </p>
          </Card>
        </UniversalLink>
        <UniversalLink
          to="https://dev.to/ripplexdev"
          className="dev-tools-card col-span-2 md:col-span-4"
        >
          <Card title="RippleX DEV Blog" trim="yellow">
            <p className="description">
              Subscribe for articles and updates related to the XRPL ecosystem.
            </p>
          </Card>
        </UniversalLink>
      </div>
      <UniversalLink
        to="https://xrpl.org/contribute.html"
        className="explore-more-button btn btn-primary btn-auto"
      >
        Explore More Resources
      </UniversalLink>
    </div>
  </Page>
)

export default About
